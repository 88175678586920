import { atom } from "recoil";


const supplierOrderCountAtom = atom({

    key:"supplierOrderCountAtomState",
    default:null

});

export default supplierOrderCountAtom;