import React,{useState} from 'react';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import qbConnectModalVisible from '../atoms/qbConnectModalVisible';
import { GLOBAL_API } from '../config/basic';

function QuickbooksAuthModal(props) {

    const [qbConnectModalvisible,setqbConnectModalVisible]=useRecoilState(qbConnectModalVisible)

    const quickbooksSignIn=async()=>{
        const aurthorizedURLcode = window.location.href
            console.log(aurthorizedURLcode)  
            localStorage.setItem('quickbooksAuthCode',aurthorizedURLcode)
           
        console.log("inside signin")
        let splitedList1 = localStorage.getItem('quickbooksAuthCode').split("&");
        let code = splitedList1[0].split("=")[1];
        let state = splitedList1[1].split("=")[1];
        let realmId = splitedList1[2].split("=")[1];
        console.log(code+" "+state +" " + realmId)
       const res= await axios.get(`${GLOBAL_API}/quickBookToken/${code}/${state}/${realmId}`,{

        headers:{

          Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`

        }

      })
       console.log(res.data)
       if(res.status==200)
       {
        localStorage.setItem('quickbooksCredentials',res.data)
        localStorage.setItem('qbConnectVisible','2')

       }
       console.log(localStorage.getItem('quickbooksCredentials'))
      
      }

    return (
        <div>
            <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      <button type="button" onClick={()=>{quickbooksSignIn()}}  classname="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">quickbooksSignIn</button>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        {/* <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">Invite</button> */}
       
      </div>
    </div>
  </div>
</div>
            
        </div>
    );
}

export default QuickbooksAuthModal;