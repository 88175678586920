import React from 'react';
import ChangePasswordComponent from '../component/ChangePasswordComponent';


function ChangePassword() {
    return (
        <div>
             <h1 className='text-center mt-8 text-4xl font-medium'>Change Password</h1>
        <div className='mt-20'> <ChangePasswordComponent/></div>
        </div>
    );
}

export default ChangePassword;