import React from 'react';
import PageNotFoundComponent from '../component/PageNotFoundComponent';

function PageNotFound() {
    return (
        <div>
            <PageNotFoundComponent/>
        </div>
    );
}

export default PageNotFound;