import React from 'react'
import LoginForm from '../component/LoginForm';

const SignIn = () => {
  return (
    <div className='bg-[#3191b4] min-h-fit justify-center items-center'> 
        <LoginForm />
    </div>    
  )
}

export default SignIn;