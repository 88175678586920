import { atom } from "recoil";


const selectedSupplierAtom = atom({

    key:"selectedSupplierAtomState",
    default:null

});

export default selectedSupplierAtom;