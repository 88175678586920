import React, { useEffect,useState } from 'react'
import axios from 'axios'
import HomepageStatitisticsCard from '../component/HomepageStatitisticsCard'
import { GLOBAL_API } from '../config/basic';

const Home = () => {


  let [ordersCount,setAllOrdersCount]=useState(0)
  let [allApprovedOrdersCount,setAllApprovedOrdersCount]=useState(0)
  let [allUnapprovedOrdersCount,setAllUnapprovedOrdersCount]=useState(0)
  let [allQbOrdersCount,setAllQbOrdersCount]=useState(0)
  let [allProductscount,setAllProductsCount]=useState(0)

  const getAllOrdersCount=async()=>{

    await axios.get(`${GLOBAL_API}/gettotalorders`).then(res=>{console.log(res.data.data[0].totalorders);setAllOrdersCount(res.data?.data[0]?.totalorders)}).catch(err=>{{console.log(err)}}
    )
  } 




    const getAllUnapprovedOrdersCount=async()=>{
      await axios.get(`${GLOBAL_API}/getallpendingpo`).then(res=>{setAllUnapprovedOrdersCount(res.data?.data[0]?.totalPendingPo)}).catch(err=>{{console.log(err)}}
      )
      
    }
    
    const getAllApprovedOrdersCount=async()=>{
      await axios.get(`${GLOBAL_API}/getallapprovedpo`).then(res=>{setAllApprovedOrdersCount(res.data?.data[0]?.totalApprovedPO)}).catch(err=>{{console.log(err)}}
      )
    }
    
    const getAllQbOrdersCount=async()=>{
      await axios.get(`${GLOBAL_API}/getallqbcreated`).then(res=>{setAllQbOrdersCount(res.data?.data[0]?.totalqbcreated)}).catch(err=>{{console.log(err)}}
      )
    }
    
    const getAllProductCount=async()=>{
      await axios.get(`${GLOBAL_API}/getallproductcount`).then(res=>{setAllProductsCount(res.data?.data[0]?.totalproducts)}).catch(err=>{{console.log(err)}}
      )
    }
    
    
    useEffect(()=>{
    
      getAllOrdersCount();
      getAllApprovedOrdersCount();
      getAllUnapprovedOrdersCount();
      getAllQbOrdersCount();
      getAllProductCount();
    
    
    },[])

  return (
    <div className='flex flex-col flex-1'> 
       <div className='p-4 flex flex-row justify-around items-center space-x-2 mt-10'> 
      <HomepageStatitisticsCard title="Total Orders Placed" value={ordersCount} bg="bg-[#3999bc]" className='bg-[#4ca4c5]'  />
      <HomepageStatitisticsCard title="Total Approved PO's" value={allApprovedOrdersCount} bg='bg-[#48a4c6]' className='bg-[#3999bc]' />
      <HomepageStatitisticsCard title="Total QB Created PO's" value={allQbOrdersCount} bg='bg-[#32a0c8]' />
     
      
    </div>  
    <div className='p-8 flex flex-row justify-around items-center space-x-2'> 
    <HomepageStatitisticsCard title="Total Unapproved PO's" value={allUnapprovedOrdersCount} bg='bg-[#3999bc]' />
    <HomepageStatitisticsCard title="Total Products Present" value={allProductscount} bg='bg-[#42a1c4]' />
    </div>
    </div>
  
  )
}

export default Home;