import React,{useState,useEffect} from 'react';
import axios from 'axios';
import Navbar from '../component/Navbar';
import selectedSupplierAtom from '../atoms/selectedSupplierAtom';
import { useRecoilState, useRecoilValue } from 'recoil';
import serviceDetailsAtom from '../atoms/ServiceState';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import completeOrderModalVisibleAtom from '../atoms/completeOrderModalVisibleAtom';
import CompleteOrderModal from '../component/CompleteOrderModal';
import moment from 'moment';
import { GLOBAL_API } from '../config/basic';


const CreateOrder = (props) => {
    const [areas,setAreas] = useState([]);
    const [areaValue,setAreaValue] = useState(null);
    const [costCenters,setCostCenters] = useState([]);
    const [costCenterValue,setCostCenterValue] =useState("");
    const [locations,setLocations] =useState([]);
    const [areaOfWorks,setAreaOfWorks]=useState([]);
    const [areaOfWorkValue,setAreaOfWorkValue] = useState(null);
    const [locationValue,setLocationValue]= useState(null);
    const [poGenerateData,setPoGenerateData] = useState("");
    const [preProcessedAreaOfWork,setPreProcessedAreaOfWork]=useState(null)
    const [preProcessedCostCenter,setPreProcessedCostCenter]=useState(null)
    const [supplierNumber,setSupplierNumber]=useState()
    const [showModal,setShowModal]=useRecoilState(completeOrderModalVisibleAtom)
    const serviceData=useRecoilValue(serviceDetailsAtom)
    const [orderObj,setOrderObj]=useState(null)    
    const [locationName,setLocationName]=useState(null)
    const [costCenterName,setCostCenterName]=useState(null)
    const [areaOfWorkName,setAreaOfWorKName]=useState(null)
    const [areaName,setAreaName]=useState(null)
  


    const getArea = async ()=>{
        const result = await axios.get(`${GLOBAL_API}/getAllArea`,{

            headers:{
    
              Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
    
            }
    
          });
        setAreas(result.data);
    };
    
    const getCostCenter = async ()=>{
        const result = await axios.get(`${GLOBAL_API}/getCostCenter/${areaValue}`,{

            headers:{
    
              Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
    
            }
    
          });
        setCostCenters(result.data);
    };

    const getLocation =async ()=>{
        const result = await axios.get(`${GLOBAL_API}/getLocation`,{

            headers:{
    
              Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
    
            }
    
          });
        setLocations(result.data);
    };

    const getAreaOfWork =async ()=>{
        const result = await axios.get(`${GLOBAL_API}/getAreaOfWork/${costCenterValue}`,{

            headers:{
    
              Authorization:`Bearer+ ${JSON.parse(localStorage.getItem("userData")).token}`
    
            }
    
          });
       setAreaOfWorks(result.data);
    };
    
    const generatePoNumber = async()=>{
        const result = await axios.post(`${GLOBAL_API}/generatePO`,{areaId:areaValue,costCenterId:costCenterValue,areaOfWorkId:areaOfWorkValue,locationId:locationValue});
        setPoGenerateData(result.data.PONumber);
    };
    useEffect(()=>{
            getArea();
            
    },[]);

    const preprocessAreaofWork=(val)=>{
        // setAreaValue(val)
        if(val.length==1)
        {
            setPreProcessedAreaOfWork("0"+"0"+val)
        }
        else if(val.length==2){
            setPreProcessedAreaOfWork("0"+val)
        }
        else{
            setPreProcessedAreaOfWork(val)
        }
    }

    const preprocessCostCenter=(val)=>{
        setCostCenterValue(val)
        if(val.length==1)
        {
            setPreProcessedCostCenter("0"+val)
        }
        else if(val.length>=2){
            setPreProcessedCostCenter(val)
        }
       
    }

    const generatePONum=()=>{
        console.log("inside fun")
        if(areaValue==null||locationValue==null||preProcessedAreaOfWork==null||preProcessedCostCenter==null)
        {
            setTimeout(()=>{
                toast.error('Please select all the valid fields', {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              },0);
              return
        }
        // let areaofWorkVal,costCenterVal;
        // if(costCenterValue.length==1){
        //     costCenterVal="0"+costCenterValue
        //     console.log("1")
        // }
        // else{
        //     costCenterVal=costCenterValue+""
        //     console.log("2")
        // }
        

        // if(areaOfWorkValue.length==1){
        //     areaofWorkVal="0"+"0"+areaOfWorkValue
        //     console.log("3")
        // }
        // else if(areaOfWorkValue.length==2){
        //     areaofWorkVal="0"+areaOfWorkValue
        //     console.log("4")
        // }
        // else{
        //     areaOfWorkValue=areaOfWorkValue
        //     console.log("5")
        // }
       
        console.log(areaValue)

        let poNum="0"+areaValue+'-'+preProcessedCostCenter+"-"+preProcessedAreaOfWork+"-"+"0"+locationValue+"-"+props.supplierNumber
        console.log('suppnum')
        // console.log(selectedSupplier)

        setPoGenerateData(poNum)
    }

    const handleCreateOrder=()=>{
        setShowModal(true)
    }

    const createOrderSubmit=async()=>{
        let serviceDataArray=serviceData
        if(serviceDataArray.length==0||poGenerateData.length==0||props.supplierQbId==null||props.customerId==null)
        {
            setTimeout(()=>{
                toast.error('Please fill all the order details', {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              },0);
              return
        }
        console.log(serviceDataArray)
        let TotalAmt=0
        let Line=[]
        let id=1
        for (let data of serviceData )
        {
            TotalAmt+=data.totalAmount
            // let lineObj={
            //     "DetailType":"ItemBasedExpenseLineDetail",
            //     "Amount":data.totalAmount,
            //     "Description":data.description,
            //     "Id":id+"",
            //     "LineNum":id*1,
            //     "ItemBasedExpenseLineDetail":{
            //         "ItemRef":{
            //             "name":data.serviceName,
            //             "value":data.serviceqbId
            //         },
            //         "Qty":data.quantity*1,
            //         "TaxCodeRef":{
            //             "value":"NON"
            //         },
            //         "BillableStatus":"Non-Billable",
            //         "UnitPrice":data.rate*1
            //     }
            // }
            // lineObj.DetailType="ItemBasedExpenseLineDetail"
            // lineObj.Amount=data.totalAmount
            // lineObj.Description=data.description
            // lineObj.Id=id+""
            // lineObj.LineNum=id*1
            // lineObj.ItemBasedExpenseLineDetail={
            //     "ItemRef":{
            //         "name":data.serviceName,
            //         "value":data.serviceqbId
            //     },
            //     "Qty":data.quantity*1,
            //     "TaxCodeRef":{
            //         "value":"NON",
            //     },
            //     "BillableStatus":"Non-Billable",
            //     "UnitPrice":data.rate*1
            // }
        Line.push({
            "DetailType":"ItemBasedExpenseLineDetail",
            "Amount":data.totalAmount,
            "Description":data.description,
            "Id":id+"",
            "LineNum":id*1,
            "ItemBasedExpenseLineDetail":{
                "ItemRef":{
                    "name":data.serviceName,
                    "value":data.serviceqbId
                },
                "Qty":data.quantity*1,
                "TaxCodeRef":{
                    "value":data.tax
                },
                "BillableStatus":"Non-Billable",
                "UnitPrice":data.rate*1
            }
        })
        id+=1;
        }
        let orderObj={
            "vendorId":props.vendorId,
            "DocNumber":poGenerateData,
            "TotalAmt":TotalAmt,
            "Line":Line,
            "customerName":props.customerName,
            "supplierName":props.supplierName,
            "APAccountRef":{
                "name": "Accounts Payable (A/P)",
                "value": "14"
            },
            "currencyValue":props.supplierCurrencyValue,
            "VendorRef":{
                "name":props.supplierName,
            "value":props.supplierQbId,
            },
            "ShipTo":{
                "name": props.customerName,
                "value": props.customerId
            }
        }
          console.log(orderObj)
          setOrderObj(orderObj)
          setShowModal(true)
        //   let quickbooksCredentials=localStorage.getItem('quickbooksCredentials')
        //   const result = await axios.post(`${GLOBAL_API}/createPO`,{data:orderObj,refreshToken:quickbooksCredentials});
        //   if(result.status==201)
        //   {
        //    console.log("toasting")
           
        //        toast.success('PO created successfully', {
        //          position: "top-center",
        //          autoClose: 2000,
        //          hideProgressBar: true,
        //          closeOnClick: true,
        //          pauseOnHover: true,
        //          draggable: true,
        //          progress: undefined,
        //        });
        //      }

        }
    

    useEffect(()=>{
        console.log("area of work")
        if (areaValue?.length>0){
            console.log("area of work condition")
        getCostCenter();
        }
    },[areaValue]);

    useEffect(()=>{
        console.log("cost center")
        if(costCenterValue?.length>0){
            console.log("cost center condition")
        getAreaOfWork();
        }
    },[costCenterValue]);

    useEffect(()=>{
        getLocation();
    },[]);
  return ( 
    <>
    { showModal && <CompleteOrderModal orderObj={orderObj}  customerCurrency={props.customerCurrency} /> }
    <div className=' w-full flex flex-row items-center justify-center  p-6'>
    <div className='w-full'>
        <div className='flex flex-row justify-center items center p-2 '>
        <div className='flex basis-1/2  justify-center  items-center p-2 space-x-2'>
            <div className='basis-1/6 '>
        <label htmlFor="location" className="float-right text-sm font-medium text-gray-900 dark:text-gray-400">Location</label>
          </div>
          <div className='basis-5/6 '>
            <select id="location" required  onChange={(e)=>{let location=e.target.value.split('-');let locName=location[1];let locValue=location[0]; setLocationValue(locValue);setLocationName(locName)}} className="w-[100%] float-left h-10 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg ">
            <option selected disabled >Choose a Location</option>
            {
                locations.map((location)=>(
                    <option value={location.id+'-'+location.locationName} key={location.id}>{location.locationName}</option>
                ))
            }
            </select>
            </div>
        </div>
        <div className='flex basis-1/2 justify-center  items-center p-2 space-x-2'>
        <div className='basis-1/6 '>
            <label htmlFor="area" className="float-right items-center text-sm font-medium text-gray-900 dark:text-gray-400">Area</label>
           </div>
           <div className='basis-5/6 '>
            <select id="area" onChange={(e)=>{let area=e.target.value.split('-');let areaName=area[1];let areaValueLocal=area[0]; setPoGenerateData(null);setPreProcessedCostCenter(null);setCostCenterName(null);setAreaOfWorKName(null); setCostCenterValue(null);setPreProcessedAreaOfWork(null);setAreaValue(areaValueLocal);setAreaName(areaName)}}  className="w-[100%] float-left h-10 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg ">
            <option selected disabled>Choose an Area</option>
   
    {   areas.map((area)=>(
                <option value={area.id+'-'+area.areaName} key={area.id}>{area.areaName}</option>
         ))
     }
            </select>
            </div>
        </div>
        </div>

        <div className='flex flex-row justify-center items center p-2'>
        <div className='flex basis-1/2 justify-center  items-center p-2 space-x-2'>  
        <div className='basis-1/6 '> 
        <label htmlFor="costCenter" className="float-right items-center text-sm font-medium text-gray-900 dark:text-gray-400">Cost Center</label>
          </div>
          <div className='basis-5/6 '>
            <select id="costCenter" onChange={(e)=>{let costcenterArr=e.target.value.split('-');let cName=costcenterArr[1];let cValue=costcenterArr[0] ;setPoGenerateData(null);setPreProcessedAreaOfWork(null); setAreaOfWorKName(null) ;preprocessCostCenter(cValue);setCostCenterName(cName)}}className="w-[100%] float-left h-10 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg " >
          
            <option selected disabled>Choose a Cost Center</option>
                    {
                         costCenters.map((cost)=>( 
                            <option value={cost.costCenterCode+'-'+cost.costCenterName} key={cost.id}>{cost.costCenterName}</option>
                        )
                        )
                    }
            </select>
            </div>
        </div>
        
        <div className='flex basis-1/2 justify-center  items-center p-2 space-x-2'>
        <div className='basis-1/6 '>  
        <label htmlFor="areaOfWork" className="float-right items-center text-sm font-medium text-gray-900 dark:text-gray-400">Area of Work</label>
         </div>
         <div className='basis-5/6 '>
            <select id="areaOfWork" onChange={(e)=>{let areaOfWorkArr=e.target.value.split('-');let areaWorkVal=areaOfWorkArr[0];let areaWorkName=areaOfWorkArr[1]; preprocessAreaofWork(areaWorkVal); setAreaOfWorKName(areaWorkName)}} className="w-[100%] float-left h-10 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg ">
           
            <option selected disabled>Choose an Area Of Work</option>
            {
                areaOfWorks.map((work)=>(
                    <option value={work.areaOfWorkCode+'-'+work.areaOfWorkName} key={work.id}>{work.areaOfWorkName}</option>
                ))
            }
            </select>
            </div>
        </div>
        </div>
        <div className='text-center mt-4'>
            <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={generatePONum}>Generate PO Number</button>
        </div>
       
        {/* <div className='flex flex-row text-center justify-center mt-4'>
         <select id="areaOfWork" onChange={(e)=>{setAreaOfWorkValue(e.target.value)}} className="w-[100%] float-left h-10 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg ">
           
           <option>Choose an Area Of Work</option>
           {
               areaOfWorks.map((work)=>(
                   <option value={work.id} key={work.id}>{work.areaOfWorkName}</option>
               ))
           }
           </select>
        </div>      */}

<div className='flex flex-row'>
{locationName?.length>0?
    <div className='flex basis-1/2 text-center justify-center mt-4'>
            <p><b>Selected Location</b> :&nbsp;&nbsp;{locationName}</p>
        </div>:null
}
{areaName?.length>0?
    <div className='flex basis-1/2 text-center justify-center mt-4'>
            <p><b>Selected Area</b> :&nbsp;&nbsp;{areaName}</p>
        </div>:null
}
</div>
<div className='flex flex-row '>
{costCenterName?.length>0?
    <div className='flex basis-1/2 text-center justify-center mt-4'>
            <p><b>Selected Cost Center </b>:&nbsp;&nbsp;{costCenterName}</p>
        </div>:null
}

{areaOfWorkName?.length>0?
    <div className='flex basis-1/2 text-center justify-center mt-4'>
            <p><b>Selected Area of Work</b>  :&nbsp;&nbsp;{areaOfWorkName}</p>
        </div>:null
}
</div>
{poGenerateData?.length>0?
    <div className='flex flex-row text-center justify-center mt-4'>
            <p>Partial PO :&nbsp;&nbsp;{poGenerateData}</p>
        </div>:null
}

<div className='text-center mt-4'>
            <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={createOrderSubmit}>Review Order</button>
        </div>
    </div>
   
    </div>
    </>
  )
}

export default CreateOrder;