import React from 'react'
import AllOrders from '../component/AllOrders'
import AllQbPo from '../component/AllQbPo'
import ViewAllSuperAdminOrders from '../component/ViewAllSuperAdminOrders'

const SuperAdminOrdersView = () => {

  

  return (
    <>

        <h1 className='text-center mt-2 text-lg font-medium'>All Orders Admin Control</h1>
        <ViewAllSuperAdminOrders/>
    </>
    
  )
}

export default SuperAdminOrdersView