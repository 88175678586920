import React from 'react'
import AllQbPo from '../component/AllQbPo'
const ViewAllPos = () => {
// Check
  return (
    <>

        <h1 className='text-center mt-2 text-lg font-medium'>All Suppliers</h1>
        <AllQbPo/>
    </>
    
  )
}

export default ViewAllPos